import React from "react";
import Sidebar from "./../components/sidebar";
import Header from "./../components/Header";
import TransacComponent from "../components/transacs/TransacComponent";

const UsersScreen = () => {
  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />
        <TransacComponent />
      </main>
    </>
  );
};

export default UsersScreen;
